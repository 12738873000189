@green: #93ba11;
@azure: #5aa1f1;
@purple: #251C3C;
@purple-gStart: #373863;
@purple-gEnd: #1e1935;
@font-size: 20px;
@font-size-mobile:16px;
@h1-size: 40px;
@h1-size-mobile: 30px;
@h2-size: 30px;
@h2-size-mobile: 25px;

@wide: 			~"only screen and (min-width: 1200px)";
@normal: 		~"only screen and (min-width: 992px) and (max-width: 1199px)";
@tablet: 		~"only screen and (min-width: 768px) and (max-width: 991px)";
@phone: 		~"only screen and (max-width: 767px)";

.white {
	color:#ffffff;
}
.white-bg {
	background-color:#ffffff;
}
.azure {
	color:@azure;
}
.azure-bg {
	background-color:@azure;
}
.green {
	color:@green;
}
.green-bg {
	background-color:@green;
}
.purple-bg {
	background-color:@purple;
}

html, body, a, button, input {
	font-family: 'Open sans', sans-serif;
	font-size:@font-size;
	@media @phone {
		font-size:@font-size-mobile;
	}
}
a {
	.azure;
}
h1 {
	font-size:@h1-size;
	@media @phone {
		font-size:@h1-size-mobile;
		&.isBigOnMobile {
			font-size:50px;
		}
	}
}
h2 {
	font-size:@h2-size;
	@media @phone {
		font-size:@h2-size-mobile;
	}
}
section {
	padding-top:80px;
	padding-bottom:80px;
	@media @phone {
		padding-top:40px;
		padding-bottom:40px;	
	}
	&.npt {
		padding-top:0px;
	}
	&.npb {
		padding-bottom:0px;
	}
}

.btn {
	border-radius:0;
	-webkit-border-radius: 0;
	-ms-border-radius: 0;
	-moz-border-radius: 0;
	font-size:@font-size;
	.white;
	text-transform:uppercase;
	font-weight:600;
	padding: 20px 40px;
	.azure-bg;
	&.btn-green {
		.green-bg;
	}	
}

input {
	&.form-control {
		font-size:@font-size;
		border-radius:0;
		-webkit-border-radius: 0;
		-ms-border-radius: 0;
		-moz-border-radius: 0;
		box-shadow: none;
		-webkit-box-shadow: none;
		-ms-box-shadow: none;
		-moz-box-shadow: none;
		line-height:22px;
		height:auto;
		padding:20px;
		&:focus {
			border-color: @green;
			outline: 0;
			-webkit-box-shadow: none;
			box-shadow: none;
		}
	}
}

.radio label, .checkbox label {
    line-height: 17px;
}

.gradientBackground {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#373863+0,1e1935+100 */
	background: #373863; /* Old browsers */
	background: -moz-linear-gradient(top,  @purple-gStart 0%, @purple-gEnd 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  @purple-gStart 0%,@purple-gEnd 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  @purple-gStart 0%,@purple-gEnd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@purple-gStart', endColorstr='@purple-gEnd',GradientType=0 ); /* IE6-9 */
}
.gradientBackgroundReverse {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e1935+0,373863+100 */
	background: #1e1935; /* Old browsers */
	background: -moz-linear-gradient(top,  @purple-gEnd 0%, @purple-gStart 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  @purple-gEnd 0%,@purple-gStart 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  @purple-gEnd 0%,@purple-gStart 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@purple-gEnd', endColorstr='@purple-gStart',GradientType=0 ); /* IE6-9 */
}

.jumbotron {
	padding-top: 0px;
    padding-bottom: 0px;
	margin:0 0 -20px 0;
	position:relative;
	background: transparent;
	max-height: 800px;
	overflow:hidden;
	video { 
		&#mainVideo{
		   width:100%;
		   height:auto;
		   	@media @phone {
				margin-top:80px;
			}
		}
	}
	#mainVideoPlayButton {
		border:none;
		background: transparent;
		position:absolute;
		top:50%;
		left:50%;
		transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		@media @phone {
			width: 70px;
			height:70px;
			padding:0;
			margin-top:35px;
			img {
				height:100%;
				width:100%;
			}
		}
	}
	#videoContent {
		position: absolute;
		left: 80px;
		right: 80px;
		bottom: 10%;
		.text-shadow {
			text-shadow: 0 5px 8px rgba(0,0,0,.4);
		}
		h1 {
			.white;
			.text-shadow;
			font-size:120px;
			@media @normal {
				font-size:80px;
			}
			@media @tablet {
				font-size:60px;
			}
			font-weight:300;
		}
		@media @phone {
			text-align:center;
		}
		button {
			.white;
			.text-shadow;
			border:none;
			background: transparent;
			font-weight:600;
			text-transform:uppercase;
			outline:none;
			font-size:41px;
			@media @normal {
				font-size:31px;
			}
			@media @tablet {
				font-size:21px;
			}
			@media @phone {
				font-size:21px;
			}
			i {
				font-size:30px;
				@media @normal {
					font-size:23px;
				}
				@media @tablet {
					font-size:16px;
				}
				@media @phone {
					font-size:14px;
				}
			}
		}
	}
	#loghi {
		position: absolute;
		left: 80px;
		right: 80px;
		top:20px;
		text-align:right;
		@media @phone {
			position:fixed;
			z-index:1;
			background-color:@purple-gStart;
			left: 0px;
			right: 0px;
			top:0px;
			line-height: 80px;
			text-align:center;
		}
	}
}

.boxes {
	display:flex;
	@media @phone {
		display:block;
	}
	
	.box {
		flex: 1 0 0;
		padding:20px 40px 50px 40px;
		&.nb {
			border:none!important;
		}
		@media @phone {
			padding: 20px 20px 60px 20px;
		}
		position:relative;
		&.double {
			flex: 2 0 0;
		}
		h2 { .white; }
		.azure;
		border: 1px solid @azure;
		@media @phone {
			border: none;
			border-bottom: 1px solid @azure;
		}
		
		&.box-azure {
			.white;
			border: none;
		}
		&.box-green {
			color:#000000;
			border: none;
			p.box-icons {
				.white;
			}	
		}
		&.box-white {
			h2 { .azure; }
			color:#000000;
			border: none;
			p.box-icons {
				.azure;
			}				
		}
		p.box-icons {
			margin:0;
			position:absolute;
			left:0;
			right: 40px;
			bottom: 20px;
			text-align:right;
		}
	}
	
	&.six-pack {
		@media @phone {
			display:flex!important;
			.box {
				border: 1px solid @azure!important;
			}
		}
	}
}

section {
	&#banner {
		background-image: url(../images/c.jpg);
		background-repeat: no-repeat;
		background-size:cover;
		background-position: 50% 50%;
		@media @phone {
			background-image: url(../images/c-alpha.jpg);
		}
	}
}

footer {
	.white;
	.purple-bg;
	line-height: 80px;
	div {
		@media @phone {
			text-align:center!important;
		}
	}
}

.alert {
    padding: 20px;
    border-radius: 0;
}